import React from 'react'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
} from '../components'

class Page extends React.Component {
  render() {
    const {
      location: { pathname },
    } = this.props

    return (
      <>
        <SEO pathname={pathname} title="O Webu" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>O Webu</Title.Main>
          </Col>
          <Gap gap="170px" />
          <Row
            width="100%"
            justifyContent="center"
          >
            <Col maxWidth="780px">
              <Text>
                Nacházíte se na stránkách, které se věnují jednomu z
                nejdůležitějších přírodních zdrojů – vodě. Stojí za nimi tým
                tvořený nadšenci, ale i odborníky, kteří se pracovně dlouhodobě
                věnují vodovodům a kanalizacím.
              </Text>
              <Gap.Paragraph />
              <Text>
                Věříme, že pomocí našeho webového projektu zvýšíme povědomí o
                důležité komoditě. Chceme vzdělat nejen širokou veřejnost, ale i
                politické činitele, kteří fungování vodovodů a kanalizací u nás
                ovlivňují.
              </Text>
              <Gap.Paragraph />
              <Text>
                Na portálu Příběh vody se dozvíte spoustu informací o vodě pro
                denní spotřebu i o dalších důležitých úlohách vody. V přehledné
                a čtivé formě zjistíte, jak se sladká voda zpracovává a čím vším
                projde, než se dostane do vašeho kohoutku. Věnujeme se i čištění
                špinavé vody nebo praktickým záležitostem, jako je třeba cena
                vodného.
              </Text>
              <Gap.Paragraph />
              <Text>
                Naším cílem je osvěta, přehledná prezentace dat a vyvrácení
                častých mýtů o vodě. Proto také naprostou většinu informací
                získáváme od organizací-oborových autorit, jako jsou
                Ministerstvo životního prostředí, World Health Organization,
                Český statistický úřad, Státní zdravotnický ústav, americký úřad
                pro vědu USGS nebo od společností spravujících vodovodní
                kanalizace v různých lokalitách.
              </Text>
            </Col>
          </Row>
          <Gap gap="285px" />
        </Container>
      </>
    )
  }
}

export default Page
